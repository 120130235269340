import { ActionTree, ActionContext } from "vuex";
import { singular } from "pluralize";

import { RootState } from "@/store";
import { PermissionState } from "./state";
import { Mutations } from "./mutations";
import { PermissionMutationType } from "./mutation-types";
import { PermissionActionType } from "./action-types";
import { asyncRoutes } from "@/router";
import { RouteRecordRaw } from "vue-router";

import { Permission } from "@/interfaces/Admin";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<PermissionState, RootState>, "commit">;

export interface Actions {
  [PermissionActionType.ACTION_SET_ROUTES](
    { commit }: AugmentedActionContext,
    permissions: Permission[],
  ): void;
}

const hasPermission = (route: RouteRecordRaw, permissionNames: string[]) => {
  const routeName = route.name as string;

  return !!permissionNames.find(permissionName => {
    const permissionNameArray = permissionName.split("-");
    const operation = permissionNameArray[0];
    const [, ...resPermissionNameArray] = permissionNameArray;
    const resource = singular(resPermissionNameArray.join("-"));

    if (routeName) {
      if (routeName.startsWith("show")) {
        return operation === "list" && routeName.includes(resource);
      }

      if (routeName.startsWith("batch")) {
        const routeOperation = routeName.split("-")[1];
        return operation === routeOperation && routeName.includes(resource);
      }

      return permissionName === route.name;
    }

    if (resource.startsWith("daily")) {
      return route.path.includes("daily");
    }

    if (route.path.includes(resource)) {
      return true;
    }

    // 新增子路由包含有效权限時的處理
    if (route.children?.some(child => child.name === permissionName)) {
      return true;
    }

    return false;
  });
};

export const filterRoutes = (routes: RouteRecordRaw[], permissionNames: string[]) => {
  const resultRoutes: RouteRecordRaw[] = [];

  routes.forEach(_route => {
    const route = { ..._route };
    if (hasPermission(route, permissionNames)) {
      if (route.children) {
        route.children = filterRoutes(route.children, permissionNames);
      }

      resultRoutes.push(route);
    }
  });

  return resultRoutes;
};

export const actions: ActionTree<PermissionState, RootState> & Actions = {
  [PermissionActionType.ACTION_SET_ROUTES](
    { commit }: AugmentedActionContext,
    permissions: Permission[],
  ) {
    const accessibleRoutes = filterRoutes(
      asyncRoutes,
      permissions.map(({ name }) => name),
    );

    commit(PermissionMutationType.SET_ROUTES, accessibleRoutes);
  },
};
