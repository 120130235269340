import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const PlanRouter: Array<RouteRecordRaw> = [
  {
    path: '/plans',
    component: Layout,
    // redirect: 'noredirect',
    meta: {
      title: 'sale-plans',
      icon: '#icon-plan'
    },
    children: [
      {
        path: 'create',
        component: () =>
          import(
            /* webpackChunkName: "plan-create" */ '@/views/plans/Create.vue'
          ),
        name: 'create-plan',
        meta: {
          title: 'createPlan',
          activeMenu: '/plans',
          hidden: true
        }
      },
      {
        path: ':id(\\d+)/edit',
        component: () =>
          import(/* webpackChunkName: "plan-edit" */ '@/views/plans/Edit.vue'),
        name: 'edit-plan',
        meta: {
          title: 'editPlan',
          noCache: true,
          activeMenu: '/plans',
          hidden: true
        }
      },
      {
        path: ':id(\\d+)',
        component: () =>
          import(/* webpackChunkName: "plan-show" */ '@/views/plans/Show.vue'),
        name: 'show-plan',
        meta: {
          title: 'showPlan',
          noCache: true,
          activeMenu: '/plans',
          hidden: true
        }
      },
      {
        path: '',
        component: () =>
          import(/* webpackChunkName: "plan-list" */ '@/views/plans/List.vue'),
        name: 'list-plans',
        meta: {
          title: 'plans',
          icon: '#icon-plan'
        }
      },
      {
        path: 'subscriptions',
        component: () =>
          import(
            /* webpackChunkName: "subscription-list" */ '@/views/plans/subscriptions/List.vue'
          ),
        name: 'list-subscriptions',
        meta: {
          title: 'subscriptions',
          icon: '#icon-subscription',
          hidden: true
        }
      },
      {
        path: 'promotions/create',
        component: () =>
          import(
            /* webpackChunkName: "promotion-create" */ '@/views/promotions/Create.vue'
          ),
        name: 'create-promotion',
        meta: {
          title: 'createPromotion',
          activeMenu: '/plans/promotions',
          hidden: true
        }
      },
      {
        path: 'promotions/sort/:id(\\d+)',
        component: () =>
          import(
            /* webpackChunkName: "promotion-sort" */ '@/views/promotions/Sort.vue'
          ),
        name: 'sort-promotion',
        meta: {
          title: 'sortPromotion',
          noCache: true,
          activeMenu: '/plans/promotions',
          hidden: true
        }
      },
      {
        path: 'promotions/edit/:id(\\d+)',
        component: () =>
          import(
            /* webpackChunkName: "promotion-edit" */ '@/views/promotions/Edit.vue'
          ),
        name: 'edit-promotion',
        meta: {
          title: 'editPromotion',
          noCache: true,
          activeMenu: '/plans/promotions',
          hidden: true
        }
      },
      {
        path: 'promotions',
        component: () =>
          import(
            /* webpackChunkName: "promotion-list" */ '@/views/promotions/List.vue'
          ),
        name: 'list-promotions',
        meta: {
          title: 'promotions',
          icon: '#icon-promotion'
        }
      },
      {
        path: 'promotions/:id(\\d+)',
        component: () =>
          import(
            /* webpackChunkName: "promotion-show" */ '@/views/promotions/Show.vue'
          ),
        name: 'show-promotion',
        meta: {
          title: 'showPromotion',
          noCache: true,
          activeMenu: '/plans/promotions',
          hidden: true
        }
      }
      // {
      //   path: 'deals/create',
      //   component: () => import(/* webpackChunkName: "deal-create" */ '@/views/deals/Create.vue'),
      //   name: 'create-deal',
      //   meta: {
      //     title: 'createDeal',
      //     activeMenu: '/deals',
      //     hidden: true
      //   }
      // },
      // {
      //   path: 'deals/edit/:id(\\d+)',
      //   component: () => import(/* webpackChunkName: "deal-edit" */ '@/views/deals/Edit.vue'),
      //   name: 'edit-deal',
      //   meta: {
      //     title: 'editDeal',
      //     noCache: true,
      //     activeMenu: '/deals',
      //     hidden: true
      //   }
      // },
      // {
      //   path: 'deals',
      //   component: () => import(/* webpackChunkName: "deal-list" */ '@/views/deals/List.vue'),
      //   name: 'list-deals',
      //   meta: {
      //     title: 'deals',
      //     icon: '#icon-deal'
      //   }
      // },
      // {
      //   path: 'deals/:id(\\d+)',
      //   component: () => import(/* webpackChunkName: "deal-edit" */ '@/views/deals/Show.vue'),
      //   name: 'show-deal',
      //   meta: {
      //     title: 'showDeal',
      //     noCache: true,
      //     activeMenu: '/deals',
      //     hidden: true
      //   }
      // }
    ]
  }
];

export default PlanRouter;
