import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const seoRouter: Array<RouteRecordRaw> = [
  {
    path: '/seos',
    component: Layout,
    // redirect: 'noRedirect',
    meta: {
      title: 'seos',
      icon: '#icon-tag'
    },
    children: [
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "seo-create" */ '@/views/seos/Create.vue'),
        name: 'create-seo',
        meta: {
          title: 'createSeo',
          activeMenu: '/seos',
          hidden: true
        }
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "seo-edit" */ '@/views/seos/Edit.vue'),
        name: 'edit-seo',
        meta: {
          title: 'editSeo',
          noCache: true,
          activeMenu: '/seos',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "seo-list" */ '@/views/seos/List.vue'),
        name: 'list-seos',
        meta: {
          title: 'seos',
          icon: '#icon-tag'
        }
      }
    ]
  }
];

export default seoRouter;
