import { createApp, Directive } from 'vue';

import App from './App.vue';
// import './pwa/registerServiceWorker'
import router from './router';
import { store } from './store';
import { loadAllPlugins } from './plugins';
import '@/assets/iconfont/iconfont.css';
import '@/styles/index.css';
import '@/styles/index.scss';
import 'normalize.css';
import * as directives from '@/directives';
import '@/permission';

import { registerEasterEgg } from '@/utils/easter-egg';

// 统一导入el-icon图标
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

const app = createApp(App);

// 加载所有插件
loadAllPlugins(app);

// 自定义指令
Object.keys(directives).forEach((key) => {
  app.directive(key, (directives as { [key: string]: Directive })[key]);
});

app.use(store).use(router).mount('#app');

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  const name = `i${key.replace(/([A-Z])/g, '-$1').toLowerCase()}`;
  app.component(name, component);
}

registerEasterEgg();
