import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const OrderRouter: Array<RouteRecordRaw> = [
  {
    path: '/orders',
    component: Layout,
    // redirect: 'noredirect',
    meta: {
      title: 'orders',
      icon: '#icon-order'
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "order-list" */ '@/views/orders/List.vue'),
        name: 'list-orders',
        meta: {
          title: 'orders',
          icon: '#icon-order'
        }
      },
      {
        path: 'payments',
        component: () => import(/* webpackChunkName: "admin-list" */ '@/views/payments/List.vue'),
        name: 'list-payments',
        meta: {
          title: 'payments',
          icon: '#icon-paymentmethod'
        }
      },
      {
        path: 'tokenRecord',
        component: () => import(/* webpackChunkName: "token-record" */ '@/views/users/TokenRecord.vue'),
        name: 'token-record',
        meta: {
          title: 'tokenRecord',
          icon: '#icon-user'
        }
      }
    ]
  }
];

export default OrderRouter;
