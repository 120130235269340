import { RouteRecordRaw } from "vue-router";
import Layout from "@/layout/Index.vue";

const CustomRouter: Array<RouteRecordRaw> = [
  {
    path: "/customer",
    component: Layout,
    // redirect: 'noredirect',
    meta: {
      title: "custom",
      icon: "#icon-custom",
    },
    children: [
      {
        path: "faq/categories",
        component: () => import("@/views/faq/Categories.vue"),
        name: "list-faq",
        meta: {
          title: "faq",
          icon: "#icon-question",
        },
      },
      {
        path: "faq/categories/:id(\\d+)",
        component: () => import("@/views/faq/Contents.vue"),
        name: "edit-faq",
        meta: {
          title: "faq",
          icon: "#icon-question",
          hidden: true,
        },
      },
      {
        path: "supports/:id(\\d+)",
        component: () => import(/* webpackChunkName: "support-show" */ "@/views/supports/Show.vue"),
        name: "show-support",
        meta: {
          title: "showSupport",
          noCache: true,
          activeMenu: "/supports",
          hidden: true,
        },
      },
      {
        path: "supports",
        component: () => import(/* webpackChunkName: "support-list" */ "@/views/supports/List.vue"),
        name: "list-supports",
        meta: {
          title: "supports",
          icon: "#icon-support",
        },
      },
    ],
  },
];

export default CustomRouter;
