import { RouteRecordRaw } from "vue-router";
import Layout from "@/layout/Index.vue";

const layoutRouter: Array<RouteRecordRaw> = [
  {
    path: "/layouts",
    component: Layout,
    // redirect: 'noRedirect',
    meta: {
      title: "layouts",
      icon: "#icon-site",
    },
    children: [
      {
        path: "global-layouts/sort/:id(\\d+)/banners",
        component: () =>
          import(
            /* webpackChunkName: "global-layout-sort-banners" */ "@/views/layouts/sort/SortBanners.vue"
          ),
        name: "sort-layout-banners",
        meta: {
          title: "sortLayoutBanners",
          noCache: true,
          activeMenu: "/layouts/global-layouts",
          hidden: true,
        },
      },
      {
        path: "global-layouts/sort/:id(\\d+)/blocks",
        component: () =>
          import(
            /* webpackChunkName: "global-layout-sort-blocks" */ "@/views/layouts/sort/SortBlocks.vue"
          ),
        name: "sort-layout-blocks",
        meta: {
          title: "sortLayoutBlocks",
          noCache: true,
          activeMenu: "/layouts/global-layouts",
          hidden: true,
        },
      },
      {
        path: "global-layouts/create",
        component: () =>
          import(/* webpackChunkName: "global-layout-create" */ "@/views/layouts/Create.vue"),
        name: "create-layout",
        meta: {
          title: "createLayout",
          activeMenu: "/layouts/global-layouts",
          hidden: true,
        },
      },
      {
        path: "global-layouts/edit/:id(\\d+)",
        component: () =>
          import(/* webpackChunkName: "global-layout-edit" */ "@/views/layouts/Edit.vue"),
        name: "edit-layout",
        meta: {
          title: "editLayout",
          noCache: true,
          activeMenu: "/layouts/global-layouts",
          hidden: true,
        },
      },
      {
        path: "global-layouts/:id(\\d+)",
        component: () =>
          import(/* webpackChunkName: "global-layout-show" */ "@/views/layouts/Show.vue"),
        name: "show-layout",
        meta: {
          title: "showLayout",
          noCache: true,
          activeMenu: "/layouts/global-layouts",
          hidden: true,
        },
      },
      {
        path: "global-layouts",
        component: () =>
          import(/* webpackChunkName: "global-layout-list" */ "@/views/layouts/List.vue"),
        name: "list-layouts",
        meta: {
          title: "listLayouts",
          icon: "#icon-site",
        },
      },
      {
        path: "pages/sort/:id(\\d+)/banners",
        component: () =>
          import(/* webpackChunkName: "page-sort-banners" */ "@/views/pages/sort/SortBanners.vue"),
        name: "sort-page-banners",
        meta: {
          title: "sortPageBanners",
          noCache: true,
          activeMenu: "/layouts/pages",
          hidden: true,
        },
      },
      {
        path: "pages/sort/:id(\\d+)/blocks",
        component: () =>
          import(/* webpackChunkName: "page-sort-blocks" */ "@/views/pages/sort/SortBlocks.vue"),
        name: "sort-page-blocks",
        meta: {
          title: "sortPageBlocks",
          noCache: true,
          activeMenu: "/layouts/pages",
          hidden: true,
        },
      },
      {
        path: "pages/edit/:id(\\d+)",
        component: () => import(/* webpackChunkName: "block-edit" */ "@/views/pages/Edit.vue"),
        name: "edit-page",
        meta: {
          title: "editPage",
          noCache: true,
          activeMenu: "/layouts/pages",
          hidden: true,
        },
      },
      {
        path: "pages/:id(\\d+)",
        component: () => import(/* webpackChunkName: "page-show" */ "@/views/pages/Show.vue"),
        name: "show-page",
        meta: {
          title: "showPage",
          noCache: true,
          activeMenu: "/layouts/pages",
          hidden: true,
        },
      },
      {
        path: "pages/create",
        component: () => import(/* webpackChunkName: "page-create" */ "@/views/pages/Create.vue"),
        name: "create-page",
        meta: {
          title: "createPage",
          activeMenu: "/layouts/pages",
          hidden: true,
        },
      },
      {
        path: "pages",
        component: () => import(/* webpackChunkName: "page-list" */ "@/views/pages/List.vue"),
        name: "list-pages",
        meta: {
          title: "pages",
          icon: "#icon-site",
        },
      },
    ],
  },
];

export default layoutRouter;
