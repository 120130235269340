/**
 * Route name 取名規則
 * create-{name}
 * edit-{name}
 * list-{name}
 * Show{name} (Show的顯示邏輯為有list時就會有Show)
 * 目前在 permission/actions.ts 會判斷是否有權限
 * 因此命名規則需依照上方邏輯寫
 */

import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const constantModules: Array<RouteRecordRaw> = [];
const constantArray = import.meta.glob('./constantModules/*.ts', {
  eager: true
});
for (const path in constantArray) {
  if (path === './constantModules/index.ts') continue;
  const module = constantArray[path];
  constantModules.push(...(module.default || []));
}

const permissionModules: Array<RouteRecordRaw> = [];
// 使用 import.meta.glob 动态导入 .ts 文件
const permissionArray = import.meta.glob('./permissionModules/*.ts', {
  eager: true
});
for (const path in permissionArray) {
  if (path === './permissionModules/index.ts') continue;
  const module = permissionArray[path];
  permissionModules.push(...(module.default || []));
}

export const constantRoutes: Array<RouteRecordRaw> = [
  {
    path: '/redirect',
    component: Layout,
    meta: { hidden: true },
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () =>
          import(
            /* webpackChunkName: "redirect" */ '@/views/redirect/Index.vue'
          )
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/dashboard/Index.vue'
          ),
        name: 'Dashboard',
        meta: {
          title: 'dashboard',
          icon: '#icon-dashboard',
          affix: true
        }
      }
    ]
  },
  ...constantModules
];

export const asyncRoutes: Array<RouteRecordRaw> = [...permissionModules];

const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes
});

export function resetRouter() {
  const newRouter = router;
  (router as any).matcher = (newRouter as any).matcher; // reset router
}

export default router;
