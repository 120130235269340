import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const ContentsRouter: Array<RouteRecordRaw> = [
  {
    path: '/contents',
    component: Layout,
    // redirect: 'noRedirect',
    meta: {
      title: 'contents',
      icon: '#icon-navigation'
    },
    children: [
      {
        path: 'videos/create',
        component: () =>
          import(
            /* webpackChunkName: "video-create" */ '@/views/videos/Create.vue'
          ),
        name: 'create-video',
        meta: {
          title: 'createVideo',
          activeMenu: '/contents',
          hidden: true
        }
      },
      {
        path: 'videos/batch-create',
        component: () =>
          import(
            /* webpackChunkName: "video-create" */ '@/views/videos/BatchCreate.vue'
          ),
        name: 'batch-create-video',
        meta: {
          title: 'batchCreateVideo',
          activeMenu: '/contents',
          hidden: true
        }
      },
      {
        path: 'videos/edit/:id',
        component: () =>
          import(
            /* webpackChunkName: "video-edit" */ '@/views/videos/Edit.vue'
          ),
        name: 'edit-video',
        meta: {
          title: 'editVideo',
          noCache: true,
          activeMenu: '/contents',
          hidden: true
        }
      },
      {
        path: 'videos/show/:id',
        component: () =>
          import(
            /* webpackChunkName: "video-edit" */ '@/views/videos/Show.vue'
          ),
        name: 'show-video',
        meta: {
          title: 'showVideo',
          noCache: true,
          activeMenu: '/contents',
          hidden: true
        }
      },
      {
        path: 'videos',
        component: () =>
          import(
            /* webpackChunkName: "video-list" */ '@/views/videos/List.vue'
          ),
        name: 'list-videos',
        meta: {
          title: 'videos',
          icon: '#icon-video'
        }
      },
      {
        path: 'tags/create',
        component: () =>
          import(
            /* webpackChunkName: "tag-create" */ '@/views/tags/Create.vue'
          ),
        name: 'create-tag',
        meta: {
          title: 'createTag',
          activeMenu: '/tags',
          hidden: true
        }
      },
      {
        path: 'tags/edit/:id(\\d+)',
        component: () =>
          import(/* webpackChunkName: "tag-edit" */ '@/views/tags/Edit.vue'),
        name: 'edit-tag',
        meta: {
          title: 'editTag',
          noCache: true,
          activeMenu: '/tags',
          hidden: true
        }
      },
      {
        path: 'tags',
        component: () =>
          import(/* webpackChunkName: "tag-list" */ '@/views/tags/List.vue'),
        name: 'list-tags',
        meta: {
          title: 'tags',
          icon: '#icon-tag'
        }
      },
      {
        path: 'models/create',
        component: () =>
          import(
            /* webpackChunkName: "model-create" */ '@/views/models/Create.vue'
          ),
        name: 'create-model',
        meta: {
          title: 'createModel',
          activeMenu: '/models',
          hidden: true
        }
      },
      {
        path: 'models/edit/:id(\\d+)',
        component: () =>
          import(
            /* webpackChunkName: "model-edit" */ '@/views/models/Edit.vue'
          ),
        name: 'edit-model',
        meta: {
          title: 'editModel',
          noCache: true,
          activeMenu: '/models',
          hidden: true
        }
      },
      {
        path: 'models',
        component: () =>
          import(
            /* webpackChunkName: "model-list" */ '@/views/models/List.vue'
          ),
        name: 'list-models',
        meta: {
          title: 'models',
          icon: '#icon-model'
        }
      },
      {
        path: 'models/show/:id(\\d+)',
        component: () =>
          import(
            /* webpackChunkName: "model-show" */ '@/views/models/Show.vue'
          ),
        name: 'show-model',
        meta: {
          title: 'showModel',
          noCache: true,
          activeMenu: '/models',
          hidden: true
        }
      },
      {
        path: 'posts/create',
        component: () =>
          import(
            /* webpackChunkName: "post-create" */ '@/views/posts/Create.vue'
          ),
        name: 'create-post',
        meta: {
          title: 'createPost',
          activeMenu: '/posts',
          hidden: true
        }
      },
      {
        path: 'posts/edit/:id(\\d+)',
        component: () =>
          import(/* webpackChunkName: "post-edit" */ '@/views/posts/Edit.vue'),
        name: 'edit-post',
        meta: {
          title: 'editPost',
          noCache: true,
          activeMenu: '/posts',
          hidden: true
        }
      },
      {
        path: 'posts',
        component: () =>
          import(/* webpackChunkName: "post-list" */ '@/views/posts/List.vue'),
        name: 'list-posts',
        meta: {
          title: 'posts',
          icon: '#icon-post'
        }
      },
      {
        path: 'posts/:id(\\d+)',
        component: () =>
          import(/* webpackChunkName: "post-show" */ '@/views/posts/Show.vue'),
        name: 'show-post',
        meta: {
          title: 'showPost',
          noCache: true,
          activeMenu: '/posts',
          hidden: true
        }
      },
      {
        path: 'advertisements/create',
        component: () =>
          import(
            /* webpackChunkName: "advertisements-create" */ '@/views/advertisements/Create.vue'
          ),
        name: 'create-advertisement',
        meta: {
          title: 'createAdvertisement',
          activeMenu: '/advertisements',
          hidden: true
        }
      },
      {
        path: 'advertisements/edit/:id(\\d+)',
        component: () =>
          import(
            /* webpackChunkName: "advertisements-edit" */ '@/views/advertisements/Edit.vue'
          ),
        name: 'edit-advertisement',
        meta: {
          title: 'editAdvertisements',
          noCache: true,
          activeMenu: '/advertisements',
          hidden: true
        }
      },
      {
        path: 'advertisements/:id(\\d+)',
        component: () =>
          import(
            /* webpackChunkName: "advertisements-show" */ '@/views/advertisements/Show.vue'
          ),
        name: 'show-advertisement',
        meta: {
          title: 'showAdvertisement',
          noCache: true,
          activeMenu: '/advertisements',
          hidden: true
        }
      },
      {
        path: 'advertisements',
        component: () =>
          import(
            /* webpackChunkName: "admin-list" */ '@/views/advertisements/List.vue'
          ),
        name: 'list-advertisements',
        meta: {
          title: 'advertisements',
          icon: '#icon-advertisement'
        }
      }
    ]
  }
];

export default ContentsRouter;
