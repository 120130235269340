import { LetItGo } from 'let-it-go';

export function registerEasterEgg() {
  const SECRET_KEYS = 'ilovebailong';

  let currentKeys = [];
  let snow: LetItGo;

  window.addEventListener('keyup', ({ key }) => {
    currentKeys.push(key);
    currentKeys = currentKeys.slice(-SECRET_KEYS.length);
    if (currentKeys.join('') === SECRET_KEYS) {
      if (snow) {
        snow.letItGoAgain();
        return;
      }

      snow = new LetItGo({
        color: '#ff4040',
        radiusRange: [1, 3]
      });

          // @ts-ignore
      snow.canvas.style.position = 'fixed';
          // @ts-ignore
      snow.canvas.style.zIndex = '9999';
          // @ts-ignore
      snow.canvas.style.pointerEvents = 'none';
    }
  });
}
